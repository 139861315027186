import Image from "next/image";
import { Button } from "../app/[lng]/(components)/Button";
import { SvgBackArrowRight } from "../app/[lng]/(dashboard)/dashboard/(components)/(svg)/SvgAll";
import { RoutesDashboard } from "../app/[lng]/(utils)/routes";
import "../styles/globals.scss";
import error404 from "../public/images/404.png";
import IntlLink from "../app/[lng]/(utils)/IntlLink";

// eslint-disable-next-line react/function-component-definition
export default function Custom404() {
  return (
    <div className="bg-backgroundD h-screen pt-10 2xl:py-20">
      <div className="max-w-xl mx-auto flex flex-col justify-center items-center ">
        <div className="">
          <Image src={error404} width={320} alt="404" />
        </div>
        <h1 className="mt-4 text-h3 text-primary font-bold">404 error</h1>
        <div className="text-[56px] text-n-100 font-bold">Page not found.</div>
        <p className="mt-2 text-[#6B7280] text-h4">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-6">
          <IntlLink url={RoutesDashboard.Home}>
            <Button type="button">
              Go back home{" "}
              <div className="pl-3">
                <SvgBackArrowRight />
              </div>
            </Button>
          </IntlLink>
        </div>
      </div>
    </div>
  );
}
