import classNames from "classnames";
import { FC, ReactNode } from "react";

type Props = {
  disabled?: boolean;
  disableassign?: boolean;
  type: "button" | "submit" | "reset";
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  delay?: boolean;
  disableCount?: boolean;
};

const Button: FC<Props> = ({ onClick, type, className, children, ...rest }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      {...rest}
      disabled={rest.disableassign || rest.disabled}
      className={classNames(
        `flex w-full justify-center items-center rounded-md border border-transparent bg-primary py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-outline focus:ring-offset-2 ${className}`,
        {
          "bg-neutral-500 hover:bg-neutral-700":
            rest.delay || rest.disableassign || rest.disableCount,
        },
      )}
    >
      {children}
    </button>
  );
};

export { Button };
