"use client";

import Link from "next/link";
import React from "react";
import { useCreateIntlPath } from "./useCreateIntlPath";

const IntlLink = ({ url, children, ...rest }: any) => {
  return (
    <Link href={useCreateIntlPath(url)} {...rest}>
      {children}
    </Link>
  );
};

export default IntlLink;
