enum Routes {
  Landing = "/landing",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  CompleteRegistration = "/sign-up/complete-registration",
  ConfirmEmail = "/sign-up/confirm-email",
  EmailConfirmed = "/sign-up/email-confirmed",
  LinkExpired = "/sign-up/link-expired",
  NewPassword = "/new-password",
  PasswordRecovery = "/password-recovery",
  Terms = "/terms",
  PrivacyPolicy = "/privacy-policy",
  Home = "/",
}

export { Routes };

enum RoutesDashboard {
  Home = "/dashboard",
  Capacity = "/capacity",
  UserManagement = "/user-management",
  PersonalDetails = "/dashboard/profile/personal-details",
  Preferences = "/dashboard/profile/preferences",
}

export { RoutesDashboard };
// eslint-disable-next-line react-hooks/rules-of-hooks

export const LinksUrl = [
  {
    id: 1,
    title: "Home",
    url: RoutesDashboard.Home,
    isActive: false,
  },
  {
    id: 2,
    title: "Capacity",
    url: RoutesDashboard.Capacity,
    beta: true,
    isActive: false,
  },
  {
    id: 3,
    title: "User management",
    url: RoutesDashboard.UserManagement,
    isActive: false,
  },
];
